<template>
  <div class="container mt-2">
    <!-- Login view -->
    <div v-if="!stats">
      <label class="w-100">
        Code:<br />
        <input
          @keyup.enter="getStats"
          v-model.trim="loginToken"
          type="password"
          class="form-control"
          :class="{ 'is-invalid': loginError }"
          id="loginToken"
          placeholder="Login code"
          required
        />
      </label>
      <p v-if="loginError" class="text-danger">Wrong code</p>
      <button @click="getStats" class="mt-1 btn btn-primary">
        <span v-if="isLoading" class="spinner-border mr-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
        View stats
      </button>
    </div>
    <!-- END Login view -->
    <!-- Stats view -->
    <div v-if="stats">
      <h1>Stats</h1>
      <table class="table table-dark">
        <thead>
          <tr>
            <th>Username</th>
            <th>Section 1</th>
            <th>Section 2</th>
            <th>Section 3</th>
            <th>Section 4</th>
            <th>Combined time</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="`${stat.userName}-${stat.times[1]}`" v-for="stat in stats">
            <td>{{ stat.userName }}</td>
            <td>{{ convert(stat.times[1]) }}</td>
            <td>{{ convert(stat.times[2]) }}</td>
            <td>{{ convert(stat.times[3]) }}</td>
            <td>{{ convert(stat.times[4]) }}</td>
            <td>{{ getCombined(stat.times) }}</td>
          </tr>
        </tbody>
      </table>
      <button @click="getStats" class="mt-1 btn btn-primary">
        <span v-if="isLoading" class="spinner-border mr-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
        Reload stats
      </button>
    </div>
    <!-- END Stats view -->
  </div>
</template>

<script>
import { getStats } from "@/firebase";
import { getTimeObject } from "@/timer";

export default {
  name: "Rankings",
  data() {
    return {
      loginToken: "",
      loginError: false,
      isLoading: false,
      stats: false,
    };
  },
  methods: {
    async getStats() {
      this.isLoading = true;
      const { data } = await getStats({ loginToken: this.loginToken });
      this.isLoading = false;
      if (data) {
        this.loginError = data.error;
        this.stats = data.stats ?? false;
      }
    },
    convert(time) {
      const { daysStr, hoursStr, mins, secs } = getTimeObject(time);
      return `${daysStr}:${hoursStr}:${mins}:${secs}`;
    },
    getCombined(times) {
      times = Object.values(times);
      const totalTime = times.reduce((total, current) => {
        return total + current;
      }, 0);
      return this.convert(totalTime);
    },
  },
};
</script>

<style scoped lang="scss">
.spinner-border {
  width: 1rem;
  height: 1rem;
}
.table {
  font-family: Arial, sans-serif;
}
button {
  font-family: Arial, sans-serif;
}
</style>
