<template>
  <StartWrapper :start="true" text="Aloita pelaaminen kirjautumalla pakkauksesta löytyvillä tunnuksilla:">
    <template v-slot:start-content>
      <div class="container">
        <div class="row flex-column align-items-center">
          <div class="col-md-6 col-lg-4 mb-4">
            <input
                v-model.trim="loginForm.name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid':loginForm.error.name }"
                id="userName"
                placeholder="Tiiminimi (keksi oma)"
                required
            />
            <div class="invalid-feedback">
              {{ loginForm.errorMessage.name }}
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-5">
            <input
                v-model.trim="loginForm.code"
                class="form-control"
                :class="{ 'is-invalid': loginForm.error.code }"
                type="password"
                id="gameCode"
                placeholder="tunnus"
                required
            />
            <div class="invalid-feedback">
              {{ loginForm.errorMessage.code }}
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <button
                @click="login"
                type="button"
                class="d-flex mb-5 justify-content-center align-items-center btn btn-purple"
            >
      <span v-if="isLoading" class="spinner-border mr-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </span>
              <span>Kirjaudu peliin</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </StartWrapper>
</template>

<script>
import { authenticateWithToken, checkCode } from "@/firebase";
import StartWrapper from "@/components/partials/StartWrapper";
import { saveState } from "@/firebase";
import { resetState } from "@/store";

export default {
  name: "Login",
  components: {StartWrapper},
  data() {
    return {
      loginForm: {
        error: { name: false, code: false },
        errorMessage: { name: "", code: "" },
        code: "",
        name: "",
      },
      isLoading: false,
    };
  },
  methods: {
    login() {
      if (this.loginForm.name.length <= 2) {
        this.loginForm.error.name = true;
        this.loginForm.errorMessage.name = "Pelaajan nimi liian lyhyt";
        return;
      }
      this.loginForm.error = { name: false, code: false };
      this.isLoading = true;
      checkCode({ name: this.loginForm.name, code: this.loginForm.code }).then(
        async (result) => {
          this.isLoading = false;
          const data = result.data;
          if (data.error) {
            this.loginForm.error.code = true;
            this.loginForm.errorMessage.code = data.message;
            console.error(`Error: ${data.message}`);
          } else {
            this.loginForm.error = { name: false, code: false };
            console.log(`${data.message}`);
            await authenticateWithToken(data.token);
            if (!this.$store.state.player.code) {
              await this.$store.commit('setCode', this.loginForm.code);
            } else if (this.$store.state.player.code !== this.loginForm.code) {
              await resetState();
              console.debug('State Reset!');
              await this.$store.commit('setCode', this.loginForm.code);
            }
            await saveState({
              userName: this.loginForm.name,
            });
            await this.$store.commit('setName', this.loginForm.name);
            await this.$store.commit('setPosition', 'start')
            await this.$router.push("/");
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~bootstrap";
.spinner-border {
  width: 20px;
  height: 20px;
}
.btn-purple {
  background: transparent linear-gradient(180deg, #7A1597 0%, #411353 100%);
  border-radius: 3px;
  padding: 13px 70px;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  span {
    color: #fff;
    font-size: 18px;
    letter-spacing: 1.8px;
    @include media-breakpoint-up(md) {
      font-size: 25px;
      letter-spacing: 2.5px;
    }
  }
}
input[type="text"], input[type="password"] {
  background: transparent;
  border: 1px solid #fff;
  letter-spacing: 1px;
  color: #fff;
  font-family: Roboto, sans-serif;
  &:focus {
    background: transparent;
    color: #fff;
  }
  &::placeholder {
    color: #fff;
    opacity: 0.8;
    font-family: Abolition, sans-serif;
  }
}
</style>
